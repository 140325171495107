import React, { useEffect, useState } from "react";

import produce from "immer";
import InfiniteScroll from "react-infinite-scroll-component";
import { isLoggedIn } from "utils/auth";

import { Paging, User } from "../../types/api";
import { getUsers } from "../../utils/firebase/functions";
import { getCreatoUser } from "../../utils/localStorage";
import UsersItems from "./UsersItems";

interface IInitialState {
  //currentUsers: User[];
  hasMore: boolean;
  nextUserId: string;
  isLoading: boolean;
}

const initialState = {
  //currentUsers: [],
  hasMore: true,
  nextUserId: "",
  isLoading: false,
};

const UsersList = () => {
  const [value, setValue] = useState<IInitialState>(initialState);
  const [currentUsers, setCurrentUsers] = useState<User[]>([]);

  useEffect(() => {
    const get = async () => {
      try {
        const { role } = getCreatoUser();
        if (isLoggedIn() && role) {
          setValue(
            produce(value, (draft) => {
              draft.isLoading = true;
            })
          );
          const users = await getUsers!({ limit: 4 });
          setValue(
            produce(value, (draft) => {
              if (users.data.paging.next != null) {
                draft.nextUserId = users.data.paging.next;
              }
              draft.isLoading = false;
            })
          );
          setCurrentUsers(users.data.users);
        }
      } catch (error) {
        console.log(error);
      }
    };

    get();
  }, []);

  const fetchData = async () => {
    try {
      const {
        data: {
          users,
          paging: { hasMore, next },
        },
      } = await getUsers!({ after: value.nextUserId, limit: 2 });
      setValue(
        produce(value, (draft) => {
          draft.hasMore = hasMore;
          if (next != null) {
            draft.nextUserId = next;
          }
        })
      );

      setCurrentUsers([...currentUsers, ...users]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {value.isLoading ? (
        "loading"
      ) : currentUsers != undefined ? (
        <InfiniteScroll
          dataLength={currentUsers.length}
          next={fetchData}
          hasMore={value.hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={<h5>END</h5>}>
          <ol>
            {currentUsers.map((data) => (
              <UsersItems
                key={data.id.toString()}
                id={data.id}
                balance={data.balance}
                paymentHistory={data.paymentHistory}
                role={data.role}
                username={data.username}
              />
            ))}
          </ol>
        </InfiniteScroll>
      ) : (
        ""
      )}
    </>
  );
};

export default UsersList;
