import { useEffect, useMemo } from "react";

import { getUser } from "api/user";
import { navigate } from "gatsby-link";

import { Role } from "../../types/api";
import useGlobalContext from "../common/useGlobalContext";
import useLoading from "../common/useLoading";

const useAdmin = () => {
  const { globalState } = useGlobalContext();
  const { isOpen: loading, setLoading } = useLoading(true)

  useEffect(() => {
    const check = async () => {
      try {
        const response = await getUser(globalState.user.id);
        if (response!.data.data.role !== Role.ADMIN) {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    check();
  }, [globalState.user, setLoading]);

  return useMemo(
    () => ({ loading }),
    [loading]
  );
};

export default useAdmin;
