import React from "react";

import SEO from "components/seo";

import UsersList from "../../components/Admin/UsersList";
import useAdmin from "../../hooks/admin/useAdmin";

const Users = () => {
  const { loading } = useAdmin();

  return (
    <>
      <SEO title="Panel"/>
      <UsersList />
    </>
  );
};

export default Users;
