import firebase from 'firebase'
import { Layout } from "gatsby-plugin-image";

import {
  Role as APIRole,
  Balance,
  ID,
  Option,
  PaymentHistory,
  Title,
} from "./api";

enum IRole {
  VISITOR
}

export type Role = APIRole | IRole

export interface IUserInfo extends Partial<firebase.UserInfo> {
  id: firebase.UserInfo['uid'];
  username: firebase.UserInfo['displayName'];
  photoURL: firebase.UserInfo['photoURL'];
  role: Role;
}

export interface IPlayerInfo extends IUserInfo {
  balance?: Balance;
  paymentHistory?: PaymentHistory[];
}

//Auth
export interface IEmailAuth {
  email: string | null;
  password: string | null;
  confirmPassword?: string | null;
}

export type NewOption = Omit<Option, "id">;

//Shopyfy
export interface ShopifyNode {
  pageContext: {
    product: {
      id: ID;
      storefrontId: ID;
      title: Title;
      tags: string[];
      onlineStoreUrl: string;
      description: string;
      variants?: [
        {
          price: number;
          displayName: string;
          id: ID;
          image: {
            altText: string;
            gatsbyImageData: {
              images: {
                source: [];
                fallback: {
                  src: string;
                };
              };
              layout: Layout;
              width: number;
              height: number;
            };
            id: ID;
          };
          storefrontId: ID;
          shopifyId: ID;
          product: {
            id: ID;
          };
        }
      ];
      handle: string;
    };
  };
}

//Admin
//Add coin
export enum BalanceOption {
  ONE = 10,
  TWO = 50,
  THREE = 100,
}