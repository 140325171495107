import React, { useState } from "react";

import useUserBalance from "../../hooks/admin/useUserBalance";
import { Role, User } from "../../types/api";
import { BalanceOption } from "../../types/common";
import { updateBalance } from "../../utils/firebase/functions";
import PrimaryButton from "../common/Buttons/Primary";

const UsersItems: React.FunctionComponent<User> = ({ id, paymentHistory, role, username }) => {
  const [addBalance, setAddBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const { loading: balanceLoading, balance } = useUserBalance({ id, onCompleted: () => setAddBalance(0) });

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLButtonElement;
    setAddBalance(parseInt(value));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await updateBalance!({
        userId: id,
        newBalance: balance + addBalance,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <li>   
      <h5>Role : {role !== Role.ADMIN ? "User" : "Admin"}</h5>
      <h5>id : {id}</h5>
      <h5>User Name :{username}</h5>
      <h5>Balance : {balanceLoading ? 'loading...': balance}</h5>
      <h5>Payment History</h5>
      <h5>Add Balance : {addBalance}</h5>

      <form onSubmit={onSubmit}>
        <PrimaryButton name="ONE" value={BalanceOption.ONE} onClick={onClick}>
          {`+ ${BalanceOption.ONE}`}
        </PrimaryButton>
        <PrimaryButton name="TWO" value={BalanceOption.TWO} onClick={onClick}>
          {`+ ${BalanceOption.TWO}`}
        </PrimaryButton>
        <PrimaryButton name="THREE" value={BalanceOption.THREE} onClick={onClick}>
          {`+ ${BalanceOption.THREE}`}
        </PrimaryButton>
        <PrimaryButton type="submit" disabled={loading || addBalance === 0}>
          {loading ? "loading" : "Confirm"}
        </PrimaryButton>
      </form>
    </li>
  );
};

export default UsersItems;
