import { useEffect, useMemo, useState } from "react";

import { Balance, ID } from "../../types/api";
import { db } from "../../utils/firebase/configs";

interface IUserBalanceProps {
  id: ID
  onCompleted?: () => void
}

const useUserBalance = ({ id, onCompleted}: IUserBalanceProps) => {
  const [balance, setBalance] = useState<Balance>(0);
  const [loading, setLoading] = useState<Boolean>(true);

  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .doc(id)
      .onSnapshot((snap) => {
        if (snap.data()) {
          setBalance(snap.data()!.balance);
        }
        onCompleted && onCompleted()
        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, [id]);

  return useMemo(
    () => ({
      balance,
      loading,
    }),
    [loading, balance]
  );
};

export default useUserBalance;
